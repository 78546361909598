<template>
  <nav v-if="breadcrumbItems && breadcrumbItems.length" aria-label="Breadcrumb">
    <ol
      :class="[`AiBreadCrumb`, `AiBreadCrumb--${variant}`]"
      itemscope
      itemtype="https://schema.org/BreadcrumbList">
      <li
        v-for="(breadcrumbItem, index) in breadcrumbItems"
        :key="`AiBreadCrumb-item--${index}`"
        class="AiBreadCrumb-item"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem">
        <ai-link
          :color="variant === 'positive' ? 'default' : 'white'"
          class="AiBreadCrumb-link"
          typo-variant="overline-02"
          :text="breadcrumbItem.label"
          undecorated
          :url="breadcrumbItem.route"
          itemprop="item"
          title-itemprop="name"
          :aria-current-value="
            breadcrumbItem.isCurrentPage ? 'page' : undefined
          "
          :aria-current="
            index === breadcrumbItems.length - 1 ? 'page' : undefined
          " />
        <meta itemprop="position" :content="`${index + 1}`" />
        <ai-icon
          :class="[`AiBreadCrumb-chevron`, `AiBreadCrumb-chevron--${variant}`]"
          name="chevron"
          :size="18" />
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
export interface BreadcrumbItem {
  label: string;
  route: string;
  isCurrentPage?: boolean;
}

export interface Props {
  breadcrumbItems: BreadcrumbItem[] | null;
  variant: 'positive' | 'inverse';
}
const props = defineProps<Props>();

useJsonld(() =>
  props.breadcrumbItems
    ? {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: props.breadcrumbItems.map((item, index) => ({
          '@context': 'https://schema.org',
          '@type': 'ListItem',
          position: index + 1,
          name: item.label,
          item: item.route,
        })),
      }
    : null,
);
</script>

<style lang="scss" scoped>
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/constants';

.AiBreadCrumb {
  display: flex;
  align-items: center;
  padding: constants.$inner-03 0;
  text-transform: uppercase;
  cursor: pointer;

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    &:last-child {
      a {
        p {
          color: colors.$neutral-500;
        }
      }
      span {
        display: none;
      }
    }
  }

  &--positive {
    color: colors.$stratos-800;
    .AiBreadCrumb-link {
      &:last-child {
        pointer-events: none;
        color: colors.$stratos-200;
      }
    }
  }
  &--inverse {
    span {
      color: colors.$neutral-200;
    }
  }
  &-AiBreadCrumb-link {
    margin-top: constants.$inner-01;
  }
  &-chevron {
    fill: colors.$stratos-500;
    margin: 0 constants.$inner-03;
    transform: rotate(90deg);
    &--inverse {
      fill: colors.$neutral-400;
    }
  }
}
</style>
